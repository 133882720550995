import React, { useState, useEffect } from 'react'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from '@reach/router'
import { navigate } from 'gatsby'

const { cssPrefix, toPrefix } = toCssPrefix('WrapperTabs__')

type TabsType = {
  label: string
  key: string
  Component: React.ReactNode
}
export interface Props {
  tabsData?: TabsType[]
  defaultActiveTab?: string
  testId?: string
}

const TabsWrapper = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.bodyText};
  .${cssPrefix} {
    display: block;
    &tabs {
      display: flex;
      overflow: scroll;
      max-width: 100 vw;
    }
    &tab-button {
      all: unset;
      cursor: pointer;
      padding: 10px 30px;
      font-size: 15px;
      line-height: 20px;
      min-width: fit-content;
    }
  }
  .active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.bodyText};
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &tabs {
        justify-content: center;
      }
    }
  }
`

const Tabs = ({ tabsData, defaultActiveTab, testId }: Props) => {
  const [activeKey, setActiveKey] = useState(defaultActiveTab ?? tabsData?.[0]?.key)
  const activeTab = tabsData?.find((tab) => activeKey === tab.key)

  useEffect(() => {
    const key = defaultActiveTab ?? tabsData?.[0]?.key
    setActiveKey(key)
  }, [tabsData, defaultActiveTab])

  return (
    <TabsWrapper data-testid={testId}>
      <div className={toPrefix('tabs')}>
        {tabsData?.map(({ label, key }) => (
          <button
            title={label}
            type="button"
            key={key}
            onClick={() => navigate(`?tab=${key}`)}
            className={`${toPrefix('tab-button')} ${key === activeKey ? 'active' : ''}`}
          >
            {label}
          </button>
        ))}
      </div>
      <div className={toPrefix('content')}>
        <AnimatePresence>
          <motion.div
            key={activeKey}
            initial={{ opacity: 0, display: 'block' }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, display: 'none' }}
          >
            {activeTab?.Component}
          </motion.div>
        </AnimatePresence>
      </div>
    </TabsWrapper>
  )
}

export default Tabs

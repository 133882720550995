import React from 'react'
import { useRecipesClientSideQuery } from './utils'
import RecipeList from './RecipesList'

type Props = {
  listId: string
  citySlug: string
  title?: string
  themeId?: string
  className?: string
}

const ClientSideRecipeList = ({ listId, citySlug, themeId, title, className = '' }: Props) => {
  const { data, loading } = useRecipesClientSideQuery(listId, citySlug)

  return <RecipeList title={title} loading={loading} themeId={themeId} className={className} {...data} />
}

export default ClientSideRecipeList

import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, Image, breakpoints } from '@ecommerce/shared'
import { Recipe } from '../../../graphql/contentfulTypes'
import useDrag from '../../../hooks/useDrag'

const { cssPrefix, toPrefix } = toCssPrefix('RecipeSlide__')

type Props = React.HTMLAttributes<HTMLDivElement> & {
  data: {
    title: Recipe['sliderTitle']
    description: Recipe['sliderDescription']
    image: string
    slug: Recipe['slug']
  }
  themeId?: string
}

const Wrapper = styled.div`
  width: 320px;
  height: 190px;
  z-index: 0;
  position: relative;
  padding: 35px 20px;
  color: ${({ theme }) => theme.colors.brandText.default};
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  border-radius: ${({ theme }) => theme.borderRadius};

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${({ theme }) => theme.colors.black25};
  }

  .${cssPrefix} {
    &image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      user-select: none;
      user-drag: none;
      border-radius: inherit;
    }

    &title,
    &description {
      margin: 0;
    }

    &title {
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 5px;
    }

    &description {
      font-size: 18px;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    width: 330px;
    height: 200px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 410px;
    height: 240px;
    padding: 25px 20px;
  }
`

const RecipeSlide = ({ data, className = '', themeId, ...restProps }: Props) => {
  const { title, description, image, slug } = data
  const { onMouseDown, onMouseUp, onTouchStart } = useDrag()
  const onClick = () => navigate(slug, { state: { themeId } })

  return (
    <Wrapper
      {...restProps}
      onMouseDown={onMouseDown}
      onMouseUp={(e) => onMouseUp(e, onClick)}
      onTouchStart={onTouchStart}
      className={`${cssPrefix} ${className}`}
    >
      <Image className={toPrefix('image')} src={image} alt={title} />
      <h2 className={toPrefix('title')}>{title}</h2>
      <p className={toPrefix('description')}>{description}</p>
    </Wrapper>
  )
}

export default RecipeSlide

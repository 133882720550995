import React, { useState } from 'react'
import styled from 'styled-components'
import { toCssPrefix, Image, breakpoints, ModalBackground } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('Establishment__')

const Wrapper = styled.div`
  padding: 0;
  padding-bottom: 32px;
  max-width: 315px;
  margin: 0 auto;
  .${cssPrefix} {
    &modal {
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      top: 0;
    }
    &card {
      display: flex;
      color: ${({ theme }) => theme.colors.bodyText};
      justify-content: center;
      text-align: left;
      &-image {
        min-width: 120px;
        min-height: 120px;
        width: 120px;
        hieght: 120px;
      }
      &-info {
        margin-left: 16px;
        width: fit-content;
      }
    }
    &header {
      font-size: 15px;
      margin: 0;
      margin-bottom: 12px;
      font-weight: 200;
      span {
        font-weight: bold;
        margin-right: 4px;
      }
    }
    &text {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
    }
    &content-btn {
      display: flex;
      justify-content: flex-end;
      &-button {
        border: none;
        cursor: pointer;
        background: ${({ theme }) => theme.colors.white};
        padding: 8.5px 25.5px;
        font-size: 13px;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.black};
        font-weight: bold;
        border-radius: ${({ theme }) => theme.borderRadius};
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    max-width: 204px;
    .${cssPrefix} {
      &card {
        flex-direction: column;
        &-image {
          min-width: 204px;
          min-height: 204px;
          margin-bottom: 14px;
        }
        &-info {
          margin: 0;
          margin-bottom: 14px;
        }
      }
      &header {
        margin-bottom: 14px;
        font-size: 16px;
      }
      &text {
        font-size: 13px;
      }
      &content-btn {
        justify-content: start;
      }
    }
  }
`

const ModalWrapper = styled.div`
  .modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-inner {
    width: 320px;
    background: ${({ theme }) => theme.colors.white};
    margin: 0 auto;
    box-shadow: 0px 8px 16px ${({ theme }) => theme.colors.black10};
    border-radius: 4px;
    padding: 21px 20px 32px 20px;
    color: ${({ theme }) => theme.colors.black};
    &-close-btn {
      display: flex;
      width: 100%;
      justify-content: end;
      margin-bottom: 21px;
      svg {
        cursor: pointer;
        fill: ${({ theme }) => theme.colors.black};
      }
    }
    &-image {
      height: 134px;
      border-radius: 4px;
      margin-bottom: 16px;
    }
    &-info {
      h3 {
        text-align: left;
        font-size: 16px;
      }
      p {
        margin-bottom: 20px;
        letter-spacing: -0.06px;
        text-align: left;
      }
    }
    &-btns {
      display: flex;
      flex-direction: column;
      button,
      a {
        text-decoration: none;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.primary};
        font-size: 17px;
        line-height: 25px;
        letter-spacing: -0.24px;
        padding: 7.5px 0;
        font-weight: bold;
      }
      button {
        margin-bottom: 15.5px;
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.white};
      }
      a {
        background: ${({ theme }) => theme.colors.primary};
        color: white;
      }
    }
    @media (${breakpoints.tabletPortrait.min}) {
      width: 435px;
      margin-top: 50px;
      padding: 24px 32px;
      &-image {
        height: 172px;
      }
      &-btns {
        flex-direction: row;
        justify-content: center;
        button,
        a {
          padding: 7.5px 35px;
        }
        button {
          margin-bottom: 0;
          margin-right: 24px;
        }
      }
    }
  }
`

type Props = {
  title: string
  description: string
  city: string
  image: string
  schedule: string
  address: string
  localImage: string
  catalog: string
}

export const Establishment = ({ title, description, city, image, schedule, address, localImage, catalog }: Props) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <Wrapper data-testid="establishment">
      {showModal ? (
        <ModalWrapper data-testid="establishment-modal">
          <ModalBackground className="modal-background">
            <div className="modal-inner">
              <div className="modal-inner-close-btn">
                <Icon
                  data-testid="establishment-modal-close-btn-one"
                  iconId="close"
                  size="24"
                  data-test="close-icon"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <div className="modal-inner-image">
                <Image src={localImage} alt={`local ${title}`} />
              </div>
              <div className="card-info modal-inner-info">
                <h3 className={toPrefix('header')}>
                  <span>{title}</span>
                  {`/ ${city}`}
                </h3>
                <p className={toPrefix('text')}>{description}</p>
                <p className={toPrefix('text')}>{`Dirección: ${address}`}</p>
                <p className={toPrefix('text')}>{`Horario: ${schedule}`}</p>
              </div>
              <div className="modal-inner-btns">
                <button
                  data-testid="establishment-modal-close-btn-two"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cerrar
                </button>
                <a target="_blank" rel="noreferrer" href={catalog}>
                  Ver Carta
                </a>
              </div>
            </div>
          </ModalBackground>
        </ModalWrapper>
      ) : null}
      <div className={toPrefix('card')}>
        <div className={toPrefix('card-image')}>
          <Image src={image} alt={title} />
        </div>
        <article className={toPrefix('card-info')}>
          <h3 className={toPrefix('header')}>
            <span>{title}</span>
            {`/ ${city}`}
          </h3>
          <p className={toPrefix('text')}>{description}</p>
        </article>
      </div>
      <div className={toPrefix('content-btn')}>
        <button
          data-testid="establishment-btn"
          type="button"
          className={toPrefix('content-btn-button')}
          onClick={() => setShowModal(true)}
        >
          Ver más
        </button>
      </div>
    </Wrapper>
  )
}

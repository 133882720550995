import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, opacitySkeleton } from '@ecommerce/shared'
import SkeletonItem from './SkeletonItem'

const { cssPrefix, toPrefix } = toCssPrefix('EstablishmentSkeleton__')

const Wrapper = styled.div`
  padding: 0;
  padding-bottom: 32px;
  max-width: 315px;
  margin: 0 auto;
  display: flex;
  width: fit-content;
  flex-direction: column;
  .${cssPrefix} {
    &image {
      background: ${({ theme }) => theme.colors.dark50};
      animation: ${opacitySkeleton} 1s linear infinite forwards;
    }
    &title {
      background: ${({ theme }) => theme.colors.dark25};
      border-radius: 18px;
    }
    &info {
      background: ${({ theme }) => theme.colors.dark25};
      border-radius: 18px;
    }
    &button {
      background: ${({ theme }) => theme.colors.dark50};
      animation: ${opacitySkeleton} 1s linear infinite forwards;
    }
  }
`

const EstablishmentSkeleton = () => (
  <Wrapper data-testid="establishments-skeleton">
    <SkeletonItem className={toPrefix('image')} width="204px" height={204} margin="0 0 14px 0" />
    <SkeletonItem className={toPrefix('title')} width="159px" height={14} margin="0 0 21px 0" />
    <SkeletonItem className={toPrefix('info')} width="191px" height={11} margin="0 0 7px 0" />
    <SkeletonItem className={toPrefix('info')} width="191px" height={11} margin="0 0 7px 0" />
    <SkeletonItem className={toPrefix('info')} width="140px" height={11} margin="0 0 18px 0" />
    <SkeletonItem className={toPrefix('button')} width="104px" height={35} />
  </Wrapper>
)

export default EstablishmentSkeleton

import useQuery from '../../hooks/useQuery'
import { Recipe } from '../../graphql/contentfulTypes'

type QueryResult = {
  orList: {
    title: string
    actionSlug: string
    itemsCollection: {
      items: Array<{
        sys: {
          id: string
        }
        sliderTitle: string
        sliderImage: {
          url: string
          sys: {
            id: string
          }
        }
        parentMoment: {
          slug: string
        }
        sliderDescription: string
        slug: string
      }>
    }
  }
}

type HookReturnData = {
  data: {
    items: Pick<Recipe, 'sliderTitle' | 'sliderDescription' | 'sliderImage' | 'slug' | 'id'>[]
    title?: string
    actionSlug?: string
  }
  loading: boolean
}

export const clientSideQuery = `query ClientSideRecipesList($listId: String!) {
    orList(id: $listId) {
      title
      actionSlug
      itemsCollection {
        items {
          ... on OrRecipe {
            sys {
              id
            }
            sliderTitle
            sliderImage {
              url
              sys {
                id
              }
            }
            sliderDescription
            slug
          }
        }
      }
    }
  }`

const formatQueryResult = (citySlug: string, data?: QueryResult) => {
  const list = data?.orList
  const formattedItems =
    data?.orList?.itemsCollection?.items?.map(({ sliderDescription, sliderTitle, ...item }) => ({
      sliderTitle,
      sliderDescription,
      id: item?.sys?.id,
      sliderImage: {
        id: item?.sliderImage?.sys?.id,
        file: {
          url: item?.sliderImage?.url,
        },
      },
      slug: `/${citySlug}/recetas/${item?.slug}`,
    })) ?? []

  return { items: formattedItems, title: list?.title, actionSlug: list?.actionSlug }
}

export const useRecipesClientSideQuery = (listId: string, citySlug: string): HookReturnData => {
  const { data, loading } = useQuery<QueryResult>(clientSideQuery, { variables: { listId } })

  return { data: formatQueryResult(citySlug, data), loading }
}

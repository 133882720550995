import React from 'react'
import styled from 'styled-components'
import NewsCard from './NewsCard'
import useQuery from '../../hooks/useQuery'
import { RichTextJson, ContentfulMLVideo, FileType } from '../../graphql/contentfulTypes'
import NewsSkeleton from '../Skeletons/NewsSkeleton'

const Wrapper = styled.div`
  padding-bottom: 100px;
`

const query = `query New($listId: String!) {
  orList(id: $listId) {
    title
    itemsCollection {
      items {
				... on OrNews {
          title
          imageAlignment
          description {
            json
          }
          descriptionImage {
            url
            fileName
            contentType
          }
          descriptionVideo {
            title
            videoDescription
            video {
              url
            }
            videoPoster {
              url
            }
          }
        }
      }
    }
  }
}`

export type QueryResult = {
  orList: {
    title: string | null
    itemsCollection: {
      items: Array<{
        title: string
        imageAlignment: string
        description: RichTextJson
        descriptionImage?: FileType | null
        descriptionVideo?: ContentfulMLVideo | null
      }>
    }
  }
}

type Props = {
  listId: string
}

const NewsCards = ({ listId }: Props) => {
  const { data, loading } = useQuery<QueryResult>(query, { variables: { listId } })
  const news = data?.orList?.itemsCollection?.items

  return (
    <Wrapper data-testid="news">
      {loading
        ? Array(8)
            .fill(0)
            .map((_, i) => <NewsSkeleton key={i} />)
        : news?.map((item) => {
            return (
              <NewsCard
                title={item.title}
                description={item.description}
                image={item.descriptionImage}
                video={item.descriptionVideo}
                imageAlignment={item.imageAlignment}
                key={item.title}
              />
            )
          })}
    </Wrapper>
  )
}

export default NewsCards

import React from 'react'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import useQuery from '../../hooks/useQuery'
import textWithIconQuery, { QueryTextWithIconResult } from '../../graphql/textWithIconQuery'

const { cssPrefix, toPrefix } = toCssPrefix('StepsWrapper__')

const Wrapper = styled.div`
  .${cssPrefix} {
    &header {
      font-size: 24px;
      line-height: 31px;
      color: ${({ theme }) => theme.colors.bodyText};
      font-weight: bold;
      margin: 0 0 24px;
    }
    &content {
      background: ${({ theme }) => theme.colors.bodyBackground.default};
      &-steps {
        display: flex;
        justify-content: space-around;
        padding: 30px 0 0 30px;
        flex-direction: column;
        margin: 0 auto 34px;
        width: fit-content;
      }
      &-step {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &-shape {
          background: ${({ theme }) => theme.colors.blackMedium};
          border-radius: 100%;
          font-size: 20px;
          line-height: 42px;
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          border-radius: 100%;
          font-weight: bold;
          justify-content: center;
        }
        &-label {
          font-size: 17px;
          line-height: 25px;
          text-align: left;
          margin-left: 16px;
        }
      }
      @media (${breakpoints.tabletPortrait.min}) {
        &-step {
          &-label {
            margin-left: 25px;
          }
        }
      }
      @media (${breakpoints.desktop.min}) {
        &-steps {
          flex-direction: row;
          width: 100%;
        }
        &-step {
          &-shape {
            width: 80px;
            height: 80px;
            font-size: 40px;
          }
          &-label {
            width: 176px;
            margin-left: 33px;
          }
        }
      }
    }
  }
`

type Props = {
  listId: string
}

const Steps = ({ listId }: Props) => {
  const { data, loading, error } = useQuery<QueryTextWithIconResult>(textWithIconQuery, { variables: { listId } })
  const orList = data?.orList
  const steps = orList?.itemsCollection?.items

  if (error !== undefined || (!steps?.length && !loading)) return null

  return (
    <Wrapper data-testid="steps">
      {orList?.title && <h2 className={toPrefix('header')}>{orList?.title}</h2>}
      <div className={toPrefix('content')}>
        <div className={toPrefix('content-steps')}>
          {steps?.map((item, index) => (
            <div key={item.title} className={toPrefix('content-step')}>
              <div className={toPrefix('content-step-shape')}>{index + 1}</div>
              <div className={toPrefix('content-step-label')}>{documentToReactComponents(item.text.json)}</div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default Steps

import React from 'react'
import { toCssPrefix, breakpoints, Anchor } from '@ecommerce/shared'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Slider from '../FramerSlider'
import RecipeSlide from './Slide'
import { Recipe } from '../../graphql/contentfulTypes'
import { Icon } from '../Icon/Icon'
import SkeletonItem from '../Skeletons/SkeletonItem'
import { RecipeSubTitle } from './styled'

const { cssPrefix, toPrefix } = toCssPrefix('MomentsRecipes__')

type Props = React.HTMLAttributes<HTMLDivElement> & {
  items: Pick<Recipe, 'sliderTitle' | 'sliderDescription' | 'sliderImage' | 'slug' | 'id'>[]
  title?: string
  actionSlug?: string
  themeId?: string
  loading?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0 25px 55px;
  position: relative;

  .${cssPrefix} {
    &title {
      margin: 24px 0;
      text-align: left;
    }

    &slide {
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }
    }

    &show-more {
      color: ${({ theme }) => theme.colors.brandText.default};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      position: absolute;
      right: 25px;
      top: 30px;
    }

    &slider {
      padding: 10px 0;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 0 90px 55px;

    .${cssPrefix} {
      &show-more {
        right: 90px;
      }

      &slide {
        margin: 0 18px;
      }
    }
  }
`

const RecipeList = ({ items, title, actionSlug, className = '', themeId, loading, ...restProps }: Props) => {
  if (items?.length === 0 || !items) return null

  return (
    <Wrapper {...restProps} className={`${cssPrefix} ${className}`}>
      {title && <RecipeSubTitle className={toPrefix('title')}>{title}</RecipeSubTitle>}
      {actionSlug && (
        <Anchor className={toPrefix('show-more')} onClick={() => navigate(actionSlug)}>
          Ver todo
          <Icon className={toPrefix('show-more-button')} iconId="chevron_right" size="26" />
        </Anchor>
      )}
      <Slider className={toPrefix('slider')} itemsCount={items.length}>
        {loading ? (
          Array(6)
            .fill(0)
            .map((_, index) => <SkeletonItem animate width="300px" height={190} margin="0 15px 0" key={index} />)
        ) : (
          <>
            {items.map((item) => (
              <RecipeSlide
                themeId={themeId}
                className={toPrefix('slide')}
                key={item.id}
                data={{
                  title: item.sliderTitle,
                  description: item.sliderDescription,
                  image: item.sliderImage.file.url,
                  slug: item.slug,
                }}
              />
            ))}
          </>
        )}
      </Slider>
    </Wrapper>
  )
}

export default RecipeList

import { ClientSideMlTextWithIcon, BlockType } from './contentfulTypes'

export interface QueryTextWithIconResult {
  orList: {
    title: string
    blockType: BlockType.TEXT_WITH_ICON
    itemsCollection: {
      items: ClientSideMlTextWithIcon[]
    }
  }
}

export default `
  query TextWithIconQuery($listId: String!) {
    orList(id: $listId) {
      title
      blockType
      paddingTop
      paddingBottom
      itemsCollection {
        items {
          ... on MlTextWithIcon {
            title
            text {
              json
            }
            icon {
              url
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import useTheme from '../hooks/useTheme'
import withPageTransition from '../components/withPageTransition'
import VamosPorOtra from '../templates/vamosxotra'
import useQuery from '../hooks/useQuery'
import { ContentfulTheme, Page, PgPageProps } from '../types/PgPages'
import Layout from '../components/Layout'
import { PageType } from '../graphql/contentfulTypes'

type QueryResult = {
  pgPageCollection: {
    items: {
      title: string
      sys: {
        id: string
      }
    }[]
  }
}

const queryData = `query VamosPorOtraPages {
  pgPageCollection(where: {pageType: "${PageType.VAMOS_POR_OTRA}"}, order: pageOrder_ASC) {
    items {
      title
      sys {
        id
      }
    } 
  }
}`

type PageData = { contentfulPgPage: { title: string; theme: ContentfulTheme } }
type PageContext = PgPageProps['pageContext']
type Props = Page<PageData, PageContext>

const VamosPorOtraPage = ({ pageContext: { currentCity, productCategories }, data }: Props) => {
  const { data: pageData } = useQuery<QueryResult>(queryData)
  const pageTheme = data?.contentfulPgPage?.theme

  const sections =
    pageData?.pgPageCollection?.items?.map((item) => ({
      pageId: item.sys.id,
      title: item.title,
    })) ?? []

  const { mergeThemes } = useTheme(pageTheme?.contentful_id)

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout categories={productCategories} title={data?.contentfulPgPage?.title} currentCity={currentCity}>
        <VamosPorOtra sections={sections} currentCity={currentCity} themeId={pageTheme?.contentful_id} />
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(VamosPorOtraPage)

export const query = graphql`
  query VamosPorOtraThemeQuery {
    contentfulPgPage(pageType: { eq: "vamos-por-otra" }, pageOrder: { eq: 1 }) {
      title
      theme {
        contentful_id
        bodyBackground
        bodyBackgroundAlt
        bodyText
        brandActive
        brandDefault
        brandHover
        brandSelected
        brandTextActive
        brandTextDefault
        brandTextHover
        brandTextSelected
        productViewWrapperBackground
        productViewContainerBackground
        bodyBackgroundImage {
          file {
            url
          }
        }
      }
    }
  }
`

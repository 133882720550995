/* eslint-disable jsx-a11y/media-has-caption */
import { Image, toCssPrefix, breakpoints } from '@ecommerce/shared'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import useDrag from '../../hooks/useDrag'

type Props = React.HTMLAttributes<HTMLVideoElement> & {
  src: string
  poster?: string
  controls?: boolean
  preload?: 'none' | 'auto' | 'metadata'
  title?: string
  description?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('Video__')

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:focus,
  &:active {
    outline: none;
  }

  &.is-playing {
    .Video__info {
      opacity: 0;
      z-index: -1;
    }
  }

  .${cssPrefix} {
    &info {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 0.35s;
      z-index: 1;

      &:focus,
      &:active {
        outline: none;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
      }
    }

    &poster {
      object-fit: cover;
    }

    &play-button {
      position: absolute;
      left: calc(50% - 42.5px);
      top: calc(50% - 42.5px);
      width: 85px;
      height: 85px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      z-index: 1;
      cursor: pointer;
      background: none;

      svg {
        width: 100%;
        height: 100%;
        fill: none;
      }
    }

    &title,
    &description {
      color: ${({ theme }) => theme.colors.brandText.default};
      margin: 0;
      font-weight: bold;
      left: 30px;
      z-index: 1;
      position: absolute;
    }

    &title {
      font-size: 32px;
      bottom: 50px;
    }

    &description {
      bottom: 25px;
      font-size: 18px;
    }

    &video {
      height: 100%;
      width: 100%;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &title {
        bottom: 68px;
      }

      &description {
        bottom: 45px;
      }
    }
  }
`

const Video = ({ poster, className = '', controls = true, title, description, ...restProps }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const onPlay = () => setIsPlaying((prev) => !prev)
  const onPosterClick = () => {
    if (videoRef.current) {
      if (!isPlaying) videoRef.current.play()
      else videoRef.current.pause()
    }
  }

  const { onMouseDown, onMouseUp, onTouchStart } = useDrag()

  return (
    <Wrapper className={`${cssPrefix} ${className} ${isPlaying ? 'is-playing' : 'is-paused'}`}>
      {poster ? (
        <div
          onMouseDown={onMouseDown}
          onMouseUp={(e) => onMouseUp(e, onPosterClick)}
          onTouchStart={onTouchStart}
          role="button"
          tabIndex={0}
          onKeyPress={onPosterClick}
          className={toPrefix('info')}
        >
          <button type="button" className={toPrefix('play-button')}>
            <svg>
              <circle cx="41" cy="41" r="40" stroke="#fff" strokeWidth="2" />
              <polygon fill="none" stroke="#fff" strokeWidth="2" points="32,25 32,58 60,42" />
            </svg>
          </button>
          <Image
            className={toPrefix('poster')}
            src={poster}
            alt={`${title}`}
            params={{
              q: 80,
              fm: 'jpg',
            }}
          />
          {title && <p className={`${toPrefix('title')} ${className}-title`}>{title}</p>}
          {description && <p className={`${toPrefix('description')} ${className}-description`}>{description}</p>}
        </div>
      ) : null}
      <video
        {...restProps}
        ref={videoRef}
        className={`${toPrefix('video')} ${className}-video`}
        onPlay={onPlay}
        onPause={onPlay}
        controls={controls}
      />
    </Wrapper>
  )
}

export default Video

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { toCssPrefix, Loading, City } from '@ecommerce/shared'
import Tabs from '../../components/Tabs'
import { SectionWrapper } from './SectionWrapper'

const { cssPrefix, toPrefix } = toCssPrefix('Wrapper__')

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.bodyBackground.alt};
  padding-top: 22px;
  min-height: 100vh;
  .${cssPrefix} {
    &loader {
      min-height: 100vh;
    }
  }
`
type Props = {
  sections: {
    pageId: string
    title: string
  }[]
  currentCity: City
  themeId: string
}

const VamosPorOtra = ({ sections, currentCity, themeId }: Props) => {
  const urlTab = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '').get('tab')
  const [activeTab, setActiveTab] = useState(urlTab ?? '')

  const tabsData =
    sections?.map((item) => ({
      Component: <SectionWrapper pageId={item.pageId} currentCity={currentCity} themeId={themeId} />,
      label: item.title,
      key: item.title.toLowerCase().replaceAll(' ', '-'),
    })) ?? []

  useEffect(() => {
    setActiveTab(urlTab ?? tabsData?.[0]?.key)
  }, [tabsData])

  return (
    <Wrapper>
      {tabsData ? (
        <Tabs tabsData={tabsData} defaultActiveTab={activeTab} />
      ) : (
        <Loading className={toPrefix('loader')} />
      )}
    </Wrapper>
  )
}

export default VamosPorOtra

import React from 'react'
import styled from 'styled-components'
import { Image, breakpoints, toCssPrefix } from '@ecommerce/shared'

const { cssPrefix, toPrefix } = toCssPrefix('BrandsGrid__')

const Wrapper = styled.div`
  padding: 18px 0;
  .${cssPrefix} {
    &container {
      overflow: hidden;
      margin: 0 auto;
      padding: 60px 0;
      background: ${({ theme }) => theme.colors.bodyBackground.default};
    }
    &brands {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-width: 375px;
      margin: 0 auto;
      img {
        opacity: 0.5;
        user-select: none;
        user-drag: none;
        width: 100px;
        height: 100px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  h2 {
    display: block;
    color: ${({ theme }) => theme.colors.bodyText};
    text-align: center;
    margin: 0 0 20px;
    font-size: 24px;
    line-height: 31px;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &brands {
        max-width: 670px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &brands {
        max-width: 940px;
        img {
          width: 135px;
          height: 135px;
        }
      }
    }
  }
`

type Props = {
  brands?: string[]
  title?: string
}

export const BrandsGrid = ({ brands, title }: Props) => {
  return (
    <Wrapper data-testid="brands-grid">
      {title && <h2>{title}</h2>}
      <div className={toPrefix('container')}>
        <div className={toPrefix('brands')}>
          {brands?.map((brand, i) => (
            <Image className="image" key={i} src={brand} alt="brand" />
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
